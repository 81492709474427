<template>
  <div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item"><a href=""> Quản lý cho thuê Quầy Vé  </a></li>
    </ol>
    <div class="container-fluid">
      <div class='row'>
          <div class="col-sm-2">
            <div class="form-group">
              <router-link to="/admin/ticket/add" class="btn btn-sm btn-primary">
                <i class="fas fa-plus"></i>
                Thêm mới Quầy Vé 
              </router-link>
            </div>
          </div>

              <div class="col-sm-1" v-if="is_loading">
                <MyLoading></MyLoading>
              </div>
               <div class="col-sm-1" v-else>
                  <div class="form-group">

                    <xlsx-workbook>
                      <xlsx-sheet
                        
                        v-for="sheet in sheets"
                        :key="sheet.name"
                        :sheet-name="sheet.name"
                        :collection="sheet.data"
                      />
                      <xlsx-download :filename="sheetName">
                        <button  class="btn btn-sm btn-success">
                           <i class="fas fa-file-export"></i>
                      Export 
                       
                        </button>
                      </xlsx-download>
                      
                    </xlsx-workbook>
                      


                     
                  </div>
                </div>

              <div class="col-sm-1">
              <div class="form-group">

                
                  


                 <router-link to="/admin/ticket/import" class="btn btn-sm btn-success ">
                   <i class="fas fa-file-import"></i>
                  Import  
                </router-link>
              </div>
            </div>

    </div>

      <div>
        <div>
          <div class="row">
            <div class="col-sm-5 mb-3">
              <input
                type="text"
                name="name"
                class="form-control"
                v-model="search"
                autocomplete="off"
                placeholder="Nhập tên..."
              /><button class="btn btn-sm btn-warning mt-3" @click="onSearch">
                <i class="fa fa-search"></i> TÌM KIẾM
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header" v-if="is_loading">
              <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                <Skeleton width="40px" height="40"></Skeleton>
              </SkeletonTheme>
            </div>
            <div class="card-header" v-else>
              <i class="fa fa-align-justify" v-if="mydata != null"></i> Quản lý
              Danh mục : ( {{ mydata.length }} )
            </div>
            <div class="card-block">
              <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th>Khu</th>
                      <th>Tên</th>
                      <th>Hình ảnh</th>
                      <th>Vị trí</th>
                      <th>Chiều dài</th>
                      <th>Chiều rộng</th>
                      <th>Diện tích</th>
                      <th>Tên Khách hàng </th>
                    
                      <th>Trạng thái</th>
                      <th>Tuỳ chọn</th>
                    </tr>
                  </thead>
                  <tbody v-if="is_loading">
                    <tr v-for="(el, k) in 10" :key="k">
                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>
                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>
                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>
                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>
                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>
                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>
                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>
                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>
                      <td>
                        <Skeleton height="40px"></Skeleton>
                      </td>
                      <td>
                        <a href="" target="_black" class="text text-primary">
                          <Skeleton></Skeleton>
                        </a>
                        <br />

                        <a href="" target="_black" class="text text-primary">
                          <Skeleton></Skeleton>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <template
                      v-for="(value, key) in paginationslice(mydata, 10, page)"
                    >
                      <tr :Key="key">
                         <td>{{ value.area }}</td>
                        <td>{{ value.boothName }}</td>
                        <td>
                          <img
                            :src="my_url + '/' + value.picture"
                            :alt="value.picture"
                            width="150"
                          />
                        </td>
                        <td>{{ value.ranger }}</td>
                        <td>{{ value.height }}m</td>
                        <td>{{ value.width }}m</td>
                       <td>{{ value.acreage }}</td>
                        <td v-html="value.content"></td>
                        
                        <td>
                          {{
                            value.descriptionStatus
                          }}
                        </td>
                        <td>
                          <router-link
                            class="btn btn-sm btn-success"
                            v-bind:to="'/admin/ticket/edit/' + value.id"
                          >
                            <i class="fa fa-edit"></i>
                            Sửa
                          </router-link>
                          <div
                            class="btn btn-sm btn-danger click_remove"
                            @click="removeElement(value.id)"
                          >
                            <i class="fa fa-remove"></i> Xoá
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>

                <nav v-if="mydata != null && mydata.length > 10">
                  <VuePagination
                    :page-count="Math.ceil(mydata.length / 10)"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="changePage"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                  >
                  </VuePagination>
                </nav>
              </SkeletonTheme>
            </div>
          </div>
        </div>
        <!--/.col-->
      </div>
    </div>
  </div>
</template>

<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";
import VuePagination from "vuejs-paginate";
import axios from "axios";

import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
export default {
  name: "News.List",
  data() {
    return {
      is_loading: true,
      form: {},
      mydata: null,
      main:null,
      page: 1,
      id_load: "",

      my_url: window.my_url,

      search: "",

      sheetName:"quan_ly_quay_ve"+(new Date()).getDate().toString()+(new Date()).getFullYear().toString()+".xlsx",
      sheets: [{ name: "BEN XE MIEN DONG", data: [  {"BUITIN":"TEST"} ] }],

    };
  },
  components: {
    Skeleton,
    SkeletonTheme,
    VuePagination,
    XlsxWorkbook, XlsxSheet, XlsxDownload 
  },

  created() {
    axios
      .get(window.my_api + "api/booth/get-booths",
          {
              headers: window.my_header
            }
        )
      .then((res) => {
        if (res.status == 200) {
          this.main=res.data.data.filter(n=>n.type==1);
         this.mydata =  res.data.data.filter(n=>n.type==1);
         this.mydata=this.mydata.sort( (a,b)=> {
              if(a.area > b.area){
                return 1;
              }
               if(a.area < b.area){
                return -1;
              }
              return 0;


         });
                   this.sheets[0]['data']=[];
                  let DataSheets=[];

                  for (var i = this.mydata.length - 1; i >= 0; i--) {
                    
                    DataSheets.push({
                           "Khu":this.mydata[i].area,
                          'Tên': this.mydata[i].boothName,
                        
                          'Vị trí': this.mydata[i].ranger == undefined ? "" :this.mydata[i].ranger  ,
                          'Chiều dài': this.mydata[i].height == undefined ? "" :this.mydata[i].height,
                          'Chiều rộng': this.mydata[i].width == undefined ? "" :this.mydata[i].width,
                          'Diện tích':  this.mydata[i].acreage == undefined ? "" :this.mydata[i].acreage ,
                          'Tên Khách hàng': this.mydata[i].content == undefined ? "" :this.mydata[i].content ,
                          'Trạng thái': this.mydata[i].descriptionStatus == undefined ? "" :this.mydata[i].descriptionStatus ,

                    });

                  } 


                  


                  this.sheets[0]['data']=DataSheets;
          this.is_loading = false;
        } else {
          console.log("ERROR", res);
        }
      });
  },
  methods: {
    changePage(e) {
      this.page = e;
    },
    paginationslice(data, pagesize, page) {
      return data.filter((value, n) => {
        return n >= pagesize * (page - 1) && n < pagesize * page;
      });
    },
    onSearch() {
      this.mydata =  this.main.filter(n=>n.type==1 && (n.boothName.toUpperCase().search(this.search.toUpperCase()) > -1 || n.area.toUpperCase().search(this.search.toUpperCase()) > -1 ) );
    },
    removeElement(id) {
      this.$swal
        .fire({
          title: "Bạn có muốn xóa?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.id_load = id;
            axios
              .delete(window.my_api + "api/booth/remove-booth", {
                data: { boothIds: [id] },

                headers: window.my_header
            
              })
              .then((res) => {
                if (res.status == 200) {
                  
                  this.$swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Xóa thành công.",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.mydata = this.mydata.filter((el) => el.id !== this.id_load);
                } else {
                  alert(
                    "Hệ thống không hoạt động, vui lòng liên hệ với Admin."
                  );
                }
                this.id_load = "";
              });
          }
        });
    },
  },
};
</script>








